.summaryPurchaseContainer {
    background: #ffffff;
    /*Size*/
    width: 1440px;
    height: 1595px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.summaryPurchaseC_boxL {
    height: 1068px !important;
}

.summaryPurchaseC_box {
    border-radius: 10px;
    box-shadow: 1px 1px 5px -3px rgba(0, 0, 0, 0.5);
    background-color: #f0f0f0;
    /*Size*/
    width: 1320px;
    height: 988px;
    padding: 50px 35px 0 35px;
    /*display: flex;*/
    /*flex-direction: column;*/
    position: relative;
    top: 30px;
}

.summaryPurchaseC_b_title {
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.summaryPurchaseC_b_months {
    margin: 60px 0 40px 50px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
}

.summaryPurchaseC_b_description {
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    color: #000000;
}

.summaryPurchaseC_b_total {
    margin: 60px 0 10px 0;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.71px;
    color: #000000;
}

.summaryPurchaseC_b_info {
    margin: 0 0 0 20px;
    font-family: 'Work Sans', sans-serif;
    color: red;
}


.summaryPurchaseC_b_horizontalLine {
    width: 1199px;
    height: 2px;
    border: solid 1px #979797;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 60px;
}

.summaryPurchaseC_b_promotionalCodeInput {
    margin: 20px 0 0 0;
    padding: 0 0 0 20px;
    background: transparent;
    width: 524px;
    height: 75px;
    border-radius: 10px;
    border: solid 1px #979797;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    opacity: 0.5;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #000000;
}

.summaryPurchaseC_b_payWith {
    margin: 60px 0 40px 0;
    text-align: center;
}

.summaryPurchaseC_b_buttonsContainer {
    margin: 0 auto;
    width: 1069px;
    height: 111px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.summaryPurchaseC_b_bc_paypalButton {
    width: 477px;
}

.hiddenComponent {
    display: none;
}