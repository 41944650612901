.banner {
    width: 1440px;
    height: 130px;
    background-color: #232323;
    /*background-color: #191919;*/
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.banner img {
    height: 75px;
    position: relative;
    left: 48px;
}

.bannerButtons {
    /*Position*/
    position: relative;
    left: 520px;
}

.bannerButtons button {
    background-color: Transparent;
    border-radius: 10px;
    border: solid 2px #979797;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin-right: 35px;
    /*Size*/
    width: 273px;
    height: 50px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.73px;
    color: #ffffff;
}

.bannerUserInfo {
    width: 500px;
    position: relative;
    left: 670px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.bannerUserInfo img {
    width: 87px;
    height: 87px;
    border: solid 1px #979797;
    border-radius: 50%;
    position: relative;
    left: 0px;
}

.bannerUserInfo_isPremium {
    width: 64px;
    height: 18px;
    border-radius: 3px;
    background-color: #ffff8d;
    display: flex;
    align-items: center;
    justify-content: center;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    position: absolute;
    top: 70px;
    left: 11.5px;
}

.bannerUserInfo_container {
    position: relative;
    left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bannerUserInfo_userName {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    margin: 0 20px 0 0;
    text-align: right;
}

.bannerUserInfo_userName:hover {
    text-decoration: underline;
    cursor: pointer;
}

.bannerUserInfo_logout {
    width: 150px;
    position: relative;
    margin: 0 0 0 20px;
    text-align: left;
}

.bannerUserInfo_logout:hover {
    text-decoration: underline;
    cursor: pointer;
}