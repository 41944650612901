.signUpContainer {
    background: #191919;
    /*Size*/
    width: 1440px;
    height: 1595px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.singUpC_buttonsContainer {
    /*Position*/
    position: relative;
    top: 80px;
}

.singUpC_bc_c2 {
    /*Size*/
    width: 567px;
    height: 89px;
    margin: 20px;
    border-radius: 12px;
    box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
}

.singUpC_bc_c2:hover {
    cursor: pointer;
}

.singUpC_bc_c2_c3 {
    height: 89px;
    display: flex;
    align-items: center;
    position: relative;
    left: 102px;
}

.singUpC_bc_c2 span {
    position: relative;
    left: 26px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.singUpC_bc_c2_c3_googleImage {
    width: 37px;
    height: 37px;
}

.singUpC_bc_c2_c3_fbImage {
    width: 20px;
    height: 39px;
}

.singUpC_bc_c2_c3_appleImage {
    width: 38px;
    height: 46px;
}

.singUpC_form {
    background-color: #232323;
    width: 567px;
    height: 770px;
    position: relative;
    top: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
}

.singUpC_f_dataContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 90px;
    width: 460px;
}

.singUpC_f_dc_name {
    margin-bottom: 40px;
    margin-top: 20px;
}

.singUpC_f_dc_email {
    margin-bottom: 40px;
    margin-top: 20px;
}

.singUpC_f_dc_password {
    margin-bottom: 40px;
    margin-top: 20px;
}

.singUpC_f_dc_input_error {
    border-bottom: solid 3px brown !important;
}

.singUpC_f_dc_input_validated {
    border-bottom: solid 3px #ffff8d !important;
}

.singUpC_f_dc_input {
    background: transparent;
    border: none;
    border-bottom: solid 3px #cecece;
    color: #ffffff;
    width: 460px;
    height: 35px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
}

.singUpC_f_dc_input:focus {
    background: transparent;
    border: none;
    border-bottom: solid 3px #cecece;
    color: #ffffff;
}

.singUpC_f_dc_label {
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    /*margin: 10px 0 10px 0;*/
    width: 460px;
}

.singUpC_f_button {
    position: relative;
    top: 110px;
}