.landingContainer {
    background-image: url("/images/fondohome.png");
    width: 1440px;
    height: 1595px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}



.landingC_purchaseForm {
    /*Position*/
    position: absolute;
    margin-top: 583px;
}

.landingC_pf_purchaseButtons {
    margin: 31px 31px 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.landingC_pf_pb_buttonContainer {
    width: 350px;
    height: 78px;
    margin: 0 12px 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.commonButton {
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
}

.landingC_pf_pb_bc_button {
    background-color: #232323;
    background-repeat: no-repeat;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    width: 326px;
    height: 70px;
    /*Font*/
    font-size: 34px;
    letter-spacing: 1.46px;
    color: #ffffff;
}

.landingC_pf_purchaseButtons button:hover, .landingC_pf_pb_bc_buttonSelected {
    background-color: #ffff8d;
    background-repeat: no-repeat;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    width: 350px;
    height: 78px;
    padding: 0;
    /*Font*/
    font-size: 36px;
    letter-spacing: 1.54px;
    color: #000000;
}

.landingC_pf_container {
    width: 537px;
    height: 689px;
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
    border: 0px solid #000000;
    background-color: #f0f0f0;
    text-align: center;
    margin: 0 auto;
}

.landingC_pf_c_title {
    width: 314px;
    height: 70px;
    border-radius: 10px;
    background-color: #232323;
    margin-left: 221px;
    margin-top: 41px;
    position: absolute;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.03px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.landingC_pf_c_description {
    border-radius: 50%;
    width: 112px;
    height: 112px;
    background-color: #ffff8d;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-left: 480px;
    position: absolute;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 34px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.46px;
    text-align: center;
    color: #000000;
}

.landingC_pf_c_price {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingC_pf_c_p_integers {
    display: inline;
    margin: 170px 0px 0px 0px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 120px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 5.14px;
    text-align: center;
    color: #000000;
}

.landingC_pf_c_p_decimals {
    display: inline;
    margin: 140px 0px 0px 0px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.14px;
    text-align: center;
    color: #000000;
}

.landingC_pf_c_currency {
    /*font-size: 20px;*/
    /*font-weight: bold;*/
    margin: 0;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.29px;
    text-align: center;
    color: #000000;
}

.landingC_pf_c_planDescription {
    margin: 40px;
}

.landingC_pf_c_planDescription h1 {
    margin: 15px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.landingC_pf_c_buyButton {
    background-color: #ffff8d;
    background-repeat: no-repeat;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    width: 366px;
    height: 86px;
    margin: 25px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: #000000;
}