.completeProfileContainer {
    background: #191919;
    /*Size*/
    width: 1440px;
    height: 1595px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.completeProfileC_title {
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.6;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
    /*Position*/
    top: 212px;
    position: absolute;
}

.completeProfileC_subtitleWoman {
    /*Position*/
    top: 295px;
    position: absolute;
}

.completeProfileC_subtitleMan {
    /*Position*/
    top: 775px;
    position: absolute;
}

.completeProfileC_subtitleWoman, .completeProfileC_subtitleMan {
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.completeProfileC_womanBox {
    top: 345px;
    position: absolute;
}

.completeProfileC_manBox {
    top: 825px;
    position: absolute;
}

.completeProfileC_womanBox, .completeProfileC_manBox {
    width: 566px;
    height: 390px;
    border-radius: 10px;
    box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
    background-color: #232323;
    /*Position*/
    display: flex;
    flex-direction: column;
    align-items: center;
}

.completeProfileC_womanBox:hover, .completeProfileC_manBox:hover {
    cursor: pointer;
}

.completeProfileC_womanBoxSelected, .completeProfileC_manBoxSelected {
    background-color: #3c3c3c !important;
}

.completeProfileC_womanBox img, .completeProfileC_manBox img {
    width: 564px;
    height: 275px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.completeProfileC_wb_description, .completeProfileC_mb_description {
    width: 531px;
    height: 55px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bfbfbf;
    /*Position*/
    top: 10px;
    position: relative;
}

.completeProfileC_wb_buttons, .completeProfileC_mb_buttons {
    /*Position*/
    top: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.completeProfileC_wb_b_button, .completeProfileC_mb_b_button {
    width: min-content;
    height: 29px;
    border-radius: 8px;
    background-color: #3c3c3c;
    white-space: nowrap;
    margin: 0 10px 0 10px;
    padding: 4px 10px 0 10px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.completeProfileC_wb_b_buttonSelected, .completeProfileC_mb_b_buttonSelected {
    background-color: #232323 !important;
}

.completeProfileC_finishButton {
    /*Position*/
    top: 1115px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
