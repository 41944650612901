.welcomeContainer {
    background-image: url("/images/welcome.png");
    width: 1440px;
    height: 1011px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcomeContainer_logo {
    width: 152px;
    height: 199px;
    position: relative;
    top: 564px;
}

.welcomeContainer_text {
    position: relative;
    top: 600px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 34px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.46px;
    text-align: center;
    color: #ffffff;
}

.welcomeContainer_logo_store {
    width: 378px;
    height: 64px;
    object-fit: contain;
    position: relative;
    top: 630px;
}