.userProfileContainer {
    background: #191919;
    /*Size*/
    width: 1440px;
    height: 1595px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userProfileContainer_userStatsContainer {
    width: 1254px;
    height: 404px;
    border-radius: 10px;
    box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
    background-color: #232323;
    position: relative;
    top: 139px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userProfileContainer_userStatsContainer_picture {
    width: 147px;
    height: 147px;
    position: absolute;
    top: -100px;
    border-radius: 50%;
}

.userProfileContainer_userStatsContainer_isPremium {
    width: 106px;
    height: 28px;
    border-radius: 8px;
    background-color: #ffff8d;
    display: flex;
    align-items: center;
    justify-content: center;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    position: absolute;
    top: 25px
}

.userProfileContainer_userStatsContainer_title {
    font-family: 'Work Sans', sans-serif;
    font-size: 50px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.48;
    letter-spacing: normal;
    color: #ffffff;
    position: relative;
    top: 100px;
}

.userProfileContainer_userStatsContainer_stats_one {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    top: 150px;
}

.userProfileContainer_userStatsContainer_stats_one_text {
    font-family: 'Work Sans', sans-serif;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 260px;
}

.userProfileContainer_userStatsContainer_stats_one_vertical_line {
    width: 4px;
    height: 104px;
    border-radius: 1.5px;
    background-color: #ffff8d;
}

.userProfileContainer_userStatsContainer_stats_one_value {
    font-family: 'Work Sans', sans-serif;
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    color: #ffff8d;
    width: 260px;
    text-align: center;
}

.userProfileContainer_userStatsContainer_stats_two {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    top: 190px;
}

.userProfileContainer_userStatsContainer_stats_two_section {
    width: 300px;
}

.icon-stopwatch, .icon-trophy, .icon-food, .icon-star {
    color: white;
    font-size: 33px;
}

.userProfileContainer_userStatsContainer_stats_two span {
    font-family: 'Work Sans', sans-serif;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.56;
    letter-spacing: normal;
    color: #fffff0;
}

.userProfileContainer_userStatsContainer_stats_two_section_value {
    margin-left: 20px;
}

.userProfileContainer_buttonsContainer {
    position: relative;
    top: 300px;
}

.userProfileContainer_buttonsContainer_personalData {
    width: 382px;
    height: 63px;
    border-radius: 10px;
    box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
    background-color: #d8d8d8;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.86px;
    text-align: center;
    color: #191919;
    margin: 0 70px 0 70px;
}

.userProfileContainer_buttonsContainer_personalData:hover {
    background-color: #ffff8d;
}

.userProfileContainer_buttonsContainer_subscription {
    width: 377px;
    height: 63px;
    border-radius: 8px;
    background-color: #d8d8d8;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #000000;
    margin: 0 70px 0 70px;
}

.userProfileContainer_buttonsContainer_subscription:hover {
    background-color: #ffff8d;
}

.btn_selected {
    background-color: #ffff8d;
}

.userProfileContainer_personalData {
    width: 568px;
    height: 404px;
    min-height: 404px;
    border-radius: 10px;
    box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
    background-color: #232323;
    position: relative;
    top: 350px;
    padding: 58px 0 0 46px;
}

.userProfileContainer_personalData_label {
    height: 31px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0 0 5px 0;
}

.userProfileContainer_personalData_data {
    background: transparent;
    border: none;
    border-bottom: solid 3px #cecece;
    color: #ffffff;
    width: 460px;
    height: 35px;
    margin: 0 0 30px 0;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.userProfileContainer_SubscriptionInformation {
    min-width: 866px;
    min-height: 404px;
    border-radius: 10px;
    box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
    background-color: #232323;
    position: relative;
    top: 350px;
    padding: 38px 0 0 33px;
    display: flex;
    flex-direction: column;
}

.userProfileContainer_SubscriptionInformation_nextRenovation {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userProfileContainer_SubscriptionInformation_nextRenovation_title {
    width: 310px;
    height: 31px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.userProfileContainer_SubscriptionInformation_nextRenovation_date {
    width: 266px;
    height: 31px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.userProfileContainer_SubscriptionInformation_nextRenovation_text {
    width: 477px;
    height: 104px;
    position: relative;
    left: 310px;
    top: 15px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    /*text-align: justify;*/
}

.userProfileContainer_SubscriptionInformation_cancelSubscriptionBtn {
    width: 305px;
    height: 48px;
    border-radius: 8px;
    border: solid 1px #979797;
    background-color: Transparent;
    left: 310px;
    top: 40px;
    position: relative;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    opacity: 0.5;
}

.userProfileContainer_SubscriptionInformation_horizontalLine {
    width: 782px;
    height: 2px;
    opacity: 0.5;
    border: solid 1.5px #cecece;
    position: relative;
    top: 70px;
}

.userProfileContainer_SubscriptionInformation_payMethod {
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 100px;
    position: relative;
}

.userProfileContainer_SubscriptionInformation_payMethod_title {
    width: 310px;
    height: 31px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.userProfileContainer_SubscriptionInformation_payMethod_method {
    width: 266px;
    height: 31px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}