.title_1 {
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 100px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 4.29px;
    text-align: center;
    color: #ffffff;
    /*Position*/
    margin-top: 312px;
    width: 750px;
    position: absolute;
}

.title_2 {
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    /*Position*/
    position: relative;
    top: 60px;
}

.subtitle_1 {
    font-family: 'Work Sans', sans-serif;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.19px;
    text-align: center;
    color: #ffffff;
}

.title_3 {
    font-family: 'Work Sans', sans-serif;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: normal;
    color: #ffffff;
    position: relative;
    top: 50px;
}

.title_4 {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    position: relative;
    top: 150px;
}

.button {
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border-radius: 10px;
    box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
    background-color: #ffff8d;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.86px;
    text-align: center;
    color: #191919;
}

.button_1 {
    width: 382px;
    height: 63px;
}

.button_2 {
    width: 563px;
    height: 63px;
}

.title_5 {
    position: relative;
    top: 190px;
    /*Font*/
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.title_5 a, .title_4 a {
    color: inherit;
}

.title_5 a:hover, .title_4 a:hover {
    color: inherit;
    text-decoration: none;
}

.modal {
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/*Modal*/
.hiddenModal {
    display: none;
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 600px; /* Could be more or less, depending on screen size */
    height: 70px;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover, .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
/*Modal*/